// configure.module.scss
import React, { useState, useEffect, useRef } from 'react';
import ChatWidget from './ChatWidget/ChatWidget.js'
import { collection, addDoc, getDoc, doc, updateDoc } from 'firebase/firestore';
import { db, storage } from '../firebase/firebaseApp';
import EmbedCodeModal from '../EmbedCode_Modal/EmbedCode.js';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import styles from './configure.module.scss';
import { FaArrowLeft } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { ChromePicker } from 'react-color';
const ConfigureWidget = () => {
  const [docId, setDocId] = useState(null);
  const [assistantId, setAssistantId] = useState(null);
  const [isDemo, setIsDemo] = useState(true)
  const [chatName, setChatName] = useState('OutRise Chat Bot');
  const [placement, setPlacement] = useState('BR');
  const [showPicker, setShowPicker] = useState(false);
  const [logo, setLogo] = useState('https://outrise.ai/OutRise_Logo.png');
  const [logoError, setLogoError] = useState(null);
  const [initMessage, setInitMessage] = useState("Hello there! How can I help?");
  const [trigger, setTrigger] = useState('PL');
  const [chatColor, setChatColor] = useState('#5142f4');
  const [color, setColor] = useState('#5142f4');
  const [showTimer, setShowTimer] = useState(false);
  const [timer, setTimer] = useState(5);
  const [headerImg, setHeaderImg] = useState('./OutRise_Logo.png');
  const [assistant, setAssistant] = useState(null);
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  let timerId
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id');
    const name = urlParams.get('name');
    const logo = urlParams.get('logo');
   
    if (id) {
      setDocId(id)
      getResearchDoc(id)
    }
    if (name) {
      setChatName(name)
    }
    if (logo) {
      setChatName(logo)
    }
  }, []);

  useEffect(() => {
    if (assistantId) {
      updateAssistantConfig()
    }

  }, [isDemo, color, placement, chatName, logo, initMessage, trigger, timer]);


  const getResearchDoc = async (docId) => {
    try {
      const urlCollection = collection(db, 'research');
      const docRef = doc(urlCollection, docId);
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
        const assistantDocId = docSnapshot.data().assistant_doc_id;
        // Now, `assistantDocId` contains the value of the "assistant_doc_id" field.
        setAssistantId(assistantDocId)
        console.log('Research Doc Id:', assistantDocId);
      } else {
        console.log('Document does not exist.');
      }
    } catch (error) {
      console.error("Error fetching document:", error.message);
    }
  };

  const updateAssistantConfig = async () => {
  
    try {
      let config = {
        isDemo: false,
        color: color,
        placement: placement,
        chatName: chatName,
        logo: logo,
        initMessage: initMessage,
        trigger: trigger,
        timer: timer
      }
      const urlCollection = collection(db, 'assistants');
      const docRef = doc(urlCollection, assistantId);
      await updateDoc(docRef, { config });
    } catch (error) {
      throw error; // Throw the error for handling at the calling site
    }
  };


  const handleLogoUpload = (event) => {
    const selectedFile = event.target.files[0];
    const fileSize = selectedFile.size / 1024 / 1024; // Convert to MB

    if (fileSize > 2) {
      setLogoError('Please upload an image that is under 2MB');
      setLogo(null);
    } else {
      uploadLogo(selectedFile)
      setLogoError(null);
    }
  };

  const uploadLogo = (file) => {
    const filePath = `company_logos/${file.name}`;
    const storageRef = ref(storage, filePath);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on('state_changed',
      (snapshot) => {

        // Observe state change events such as progress, pause, and resume
        console.log('Upload is ' + (snapshot.bytesTransferred / snapshot.totalBytes) * 100 + '% done');
        if ((snapshot.bytesTransferred / snapshot.totalBytes) * 100 > 0) {
        }
      },
      (error) => {
        // Handle unsuccessful uploads
        console.error(error);
      },
      () => {
        // Handle successful uploads on complete
        console.log('Upload successful');

        // You can get the download URL of the uploaded file
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          console.log('File available at', downloadURL);
          setLogo(downloadURL)
        });

      }
    );
  }

  const addAssistantToFirebase = async (assistantData) => {
    try {
      if (!assistantData || typeof assistantData !== 'object') {
        throw new Error('Invalid assistant data. Data must be an object.');
      }
      const assistantsCollection = collection(db, 'assistants');
      const newDocRef = await addDoc(assistantsCollection, assistantData);
      console.log('Document added with ID: ', newDocRef.id);
      return newDocRef.id; // Return the new document ID if needed
    } catch (error) {
      console.error('Error adding document: ', error.message);
      throw error; // Throw the error for handling at the calling site
    }
  };
  const updatePlacement = (placement) => {
    setPlacement(placement)

  }

  const updateTrigger = (trigger) => {
    if (trigger === "Custom") {
      setShowTimer(true)
    } else {
      setShowTimer(false)
    }
    setTrigger(trigger)

  }

  const handleColorChangeComplete = (color, event) => {
    setChatColor(color.hex)
  }

  const handleTimerChange = (event) => {
    const currentValue = event.target.value;
    clearTimeout(timerId);
    timerId = setTimeout(() => {
      setTimer(currentValue);
    }, 500);

  };


  const handleColorChange = (color, event) => {
    setColor(color.hex)
  }

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleChatNameChange = (e) => {
    setChatName(e.target.value)
  }

  const handleInitMessageChange = (e) => {
    setInitMessage(e.target.value)
  }
 
 

  return (
    <div className={styles.pageWrapper}>
      <div className={placement==="TL" ? styles.linkBL : styles.link} onClick={() => {
        window.location.href = "/?id=" + docId
      }}>
        <FaArrowLeft />
        <div>Go to Training</div>
      </div>
      {docId ?
        <ChatWidget assistant_doc_id={assistantId} config={{
          isDemo: true,
          color: color,
          placement: placement,
          chatName: chatName,
          logo: logo,
          initMessage: initMessage,
          trigger: trigger,
          timer: timer
        }} /> : null
      }
      <div id="outrise_parent_id"></div>

      <div className={styles.customizeContainer}>

        <div style={{ display: 'flex', alignItems: 'center', margin: 'auto', marginTop: '00px' }}>
          <img style={{ height: '80px', marginRight: '0px' }} src="./OutRise_Logo.png" />
          <div style={{ color: 'white', fontSize: '48px', fontWeight: 700 }}>OutRise.ai</div>
        </div>
        <h2 style={{ textAlign: 'center', marginBottom: '0px' }}>Customize Your Chatbot</h2>
        <p style={{ textAlign: 'center', marginBottom: '16px' }}>Start chatting with your assistant, the more you chat and interact the more intelligent it gets about your business.</p>

        <div className={styles.sectioheader}>Name Your Assistant</div>
        <section className={styles.firstMessageSection}>
          <input className={styles.initMessageInput} style={{ color: '#6d62ec' }} type="text" value={chatName} onChange={handleChatNameChange} />
        </section>
        <div className={styles.sectioheader}>Set Initial Message</div>
        <section className={styles.firstMessageSection}>
          <input className={styles.initMessageInput} style={{ color: '#6d62ec' }} type="text" value={initMessage} onChange={handleInitMessageChange} />
        </section>
        <div className={styles.sectioheader}>Page Placement:</div>
        <section className={styles.triggerSection}>
          <div className={placement === "TL" ? styles.placementPillsSelected : styles.placementPillsUnselected} onClick={() => { updatePlacement("TL") }}>Top Left</div>
          <div className={placement === "TR" ? styles.placementPillsSelected : styles.placementPillsUnselected} onClick={() => { updatePlacement("TR") }}>Top Right</div>
          <div className={placement === "BR" ? styles.placementPillsSelected : styles.placementPillsUnselected} onClick={() => { updatePlacement("BR") }}>Bottom Right</div>
          <div className={placement === "BL" ? styles.placementPillsSelected : styles.placementPillsUnselected} onClick={() => { updatePlacement("BL") }}>Bottom Left</div>
        </section>
        <div className={styles.sectioheader}>Open Chat Widget:</div>
        <section className={styles.triggerSection}>
          <div className={trigger === "PL" ? styles.placementPillsSelected : styles.placementPillsUnselected} onClick={() => { updateTrigger("PL") }}>On Page Load</div>
          <div className={trigger === "UC" ? styles.placementPillsSelected : styles.placementPillsUnselected} onClick={() => { updateTrigger("UC") }}>On User Click</div>
          <div className={trigger === "Custom" ? styles.placementPillsSelected : styles.placementPillsUnselected} onClick={() => { updateTrigger("Custom") }}>On Timer</div>
          {showTimer ?
            <div className={styles.customerTimerContainer}>
              <div>Open chat widget after </div>
              <input type="number" defaultValue={5} className={styles.customerTimerInput} onChange={handleTimerChange} />
              <div>seconds</div>
              {/* <div className={styles.test_timer} onClick={handleCurTimerChange}>Test</div> */}
            </div> : null
          }
        </section>
        <div className={styles.uploadButtonContainer}>
          <div className={styles.sectioheader}>Logo:</div>

          <input
            type="file"
            ref={fileInputRef}
            onChange={handleLogoUpload}
            accept="image/png, image/jpeg"
            className={styles.fileInput}
          />

          {!logo ?
            <button className={styles.uploadButton} onClick={handleButtonClick}>
              {selectedFile ? 'Change File' : 'Upload File'}
            </button> : <div style={{ display: 'flex' }}>
              <img src={logo} style={{ height: '64px' }}></img>
              <div className={styles.closeBtnContainer} onClick={() => { setLogo(null) }}>
                <IoClose />
              </div>
            </div>
          }
          {selectedFile && <p className={styles.fileName}>{selectedFile.name}</p>}
        </div>
        <div className={styles.sectioheader}>Brand Color:</div>
        <div className={styles.curColor} style={{ backgroundColor: chatColor }} onClick={() => { setShowPicker(!showPicker) }}></div>
        {showPicker ?
          <ChromePicker onChangeComplete={handleColorChangeComplete} onChange={handleColorChange} color={color} /> : null
        }

        <button className={styles.uploadButton} style={{ height: '48px', marginTop: '14px', marginBottom: '14px' }} onClick={async()=>{await updateAssistantConfig(); window.location.href="https://app.outrise.ai/signup?assistant_id="+assistantId}}>Get Embed Code</button>
        
      </div>
    
      <div style={{ height: '120px' }}></div>
    </div>
  );
};

export default ConfigureWidget;
