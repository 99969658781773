import React, { useEffect, useState, useRef } from 'react';
import TypingIndicator from './TypingIndicator';
import { FaPaperPlane, FaMicrophone } from 'react-icons/fa/index.esm.js';
import { IoIosCloseCircle } from "react-icons/io/index.esm.js";
import { IoMicOutline } from "react-icons/io5/index.esm.js";
import './ChatWidget.css';

function ChatWidget(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [isVoice, setIsVoice] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [assistantDoc, setAssistantDoc] = useState(null);
  const [config, setConfig] = useState(null);
  const [allMessages, setAllMessages] = useState([]);
  const [threadID, setThreadID] = useState(null)
  const [newMessageInput, setNewMessageInput] = useState('');
  const chatPaneRef = useRef();

  useEffect(() => {
    if (props.assistant_doc_id) {
      getAssistant(props.assistant_doc_id);
    }
  }, [props.assistant_doc_id]);

  useEffect(() => {
    if (props.config) {
      setConfig(props.config);
    }
  }, [props.config]);

  const getAssistant = async (assistant_doc_id) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_REPLIT_ASSISTANT_DOC_LOOKUP}/getAssistant/${assistant_doc_id}`);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      // console.log(data)
      setAssistantDoc(data);
      if (!props.config) {
        setConfig(data.config);
      }
    } catch (error) {
      console.error(error.message);
    }
  };



  useEffect(() => {
    if (config && config.trigger) {

      if (config.trigger === "UC") {
        setIsOpen(false);
      }
      if (config.trigger === "PL") {
        setIsOpen(true);
      }
      if (config.trigger === "Custom" && config.timer !== "") {
        setIsOpen(false);
        setTimeout(() => {
          setIsOpen(true);
        }, config.timer * 1000);
      }
    }
  }, [config]);

  useEffect(() => {
    let mediaRecorder;
    let socket;

    const startTranscription = (stream) => {
      mediaRecorder = new MediaRecorder(stream, { mimeType: 'audio/webm' });
      const token = process.env.REACT_APP_DEEPGRAM_TOKEN;
      socket = new WebSocket('wss://api.deepgram.com/v1/listen', [
        'token',
        token,
      ]);

      socket.onopen = () => {
        mediaRecorder.addEventListener('dataavailable', (event) => {
          if (socket.readyState === WebSocket.OPEN) {
            socket.send(event.data);
          }
        });
        mediaRecorder.start(250);
      };

      socket.onmessage = (message) => {
        const received = JSON.parse(message.data);
        const newWords = received.channel.alternatives[0].words;

        if (newWords !== "") {
          const newTranscript = newWords.map(word => word.word).join(' ');
          setNewMessageInput(prevTranscript => prevTranscript + ' ' + newTranscript);
        }
      };
    };

    const stopTranscription = () => {
      if (mediaRecorder && mediaRecorder.state === 'recording') {
        mediaRecorder.stop();
      }

      if (socket && (socket.readyState === WebSocket.OPEN || socket.readyState === WebSocket.CONNECTING)) {
        socket.close();
      }
    };

    if (isVoice) {
      navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
        startTranscription(stream);
      });
    } else {
      stopTranscription();
    }

    return () => {
      stopTranscription();
    };
  }, [isVoice]);

  const sendUserMessageToOpenAI = async (msg) => {
    setIsGenerating(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_REPLIT_MESSAGING_SERVICE}/api/user-message`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          prompt: msg,
          max_tokens: 100,
          thread_id: threadID,
          assistant_id: assistantDoc.assistant.id,
        }),
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch data: ${response.statusText}`);
      }

      const responseData = await response.json();
      if (threadID !== responseData.message.data[0].thread_id) {
        setThreadID(responseData.message.data[0].thread_id);
        // console.log("AssitantDoc", assistantDoc)
        await captureThread(responseData.message.data[0].thread_id, assistantDoc.docId, msg)

      }
      setIsGenerating(false);
      const newMessages = Array.isArray(responseData.message.data) ? responseData.message.data : [];
      setAllMessages(newMessages.sort((a, b) => a.created_at - b.created_at));
    } catch (error) {
      console.error('Error fetching data:', error.message);
    }
  };

  useEffect(() => {
    if (chatPaneRef?.current) {
      chatPaneRef.current.scrollTop = chatPaneRef.current.scrollHeight;
    }
  }, [allMessages]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      sendUserMessageToOpenAI(newMessageInput);
      const newMessage = {
        role: 'user',
        content: [{ text: { value: newMessageInput } }],
        created_at: Date.now(),
      };
      setAllMessages((prevMessages) => [...prevMessages, newMessage]);
      setNewMessageInput('');
    }
  };

  const handleVoiceStart = () => {
    setIsVoice(true);
  };

  const handleVoiceEnd = () => {
    setIsVoice(false);
  };



  const captureThread = async (threadId, assistantDocId, msg) => {
    const apiUrl = `${process.env.REACT_APP_REPLIT_ASSISTANT_DOC_LOOKUP}/captureThread/?thread_id=${threadId}&assistant_doc_id=${assistantDocId}&msg=${msg}`;
    try {
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      //console.log('API Response:', data);
    } catch (error) {
      console.error('Fetch error:', error);
    }
  };



  return (
    <div>
      {config && (
        !isOpen ? (
          <div id={config.placement === "BL" ? "or-wid-collapsed-chat-btn-bl" : config.placement === "BR" ? "or-wid-collapsed-chat-btn-br" : config.placement === "TL" ? "or-wid-collapsed-chat-btn-tl" : "or-wid-collapsed-chat-btn-tr"} onClick={() => { setIsOpen(true) }}>
            <img id="or-wid-brand-logo-collaped-chat-btn" src={config.logo} alt="brand-logo" />
          </div>
        ) : (
          <div id={config.placement === "BL" ? "or-wid-chatPane-bl" : config.placement === "BR" ? "or-wid-chatPane-br" : config.placement === "TL" ? "or-wid-chatPane-tl" : "or-wid-chatPane-tr"}>
            <div className="or-wid-header" style={{ backgroundColor: config.color }}>
              <div id="or-wid-logoContainer">
                <img className="or-wid-or-logo" src={config.logo} alt="logo" />
              </div>
              <div id="or-wid-chat-name">{config.chatName}</div>
              <IoIosCloseCircle style={{ fontSize: '20px', marginTop: '10px', marginRight: '16px' }} onClick={() => { setIsOpen(false) }} />
            </div>
            <div className="or-wid-messages" ref={chatPaneRef}>
              <div className="or-wid-msgContainer">
                <div
                  className={"or-wid-botMessage"}
                  style={{ backgroundColor: '#efecf1', color: 'black' }}
                >{config.initMessage}</div>
              </div>
              {Array.isArray(allMessages) ? (
                allMessages.map((message, index) => (
                  <div className="or-wid-msgContainer" key={index}>
                    <div
                      className={message?.role === 'user' ? "or-wid-userMessage" : "or-wid-botMessage"}
                      dangerouslySetInnerHTML={{ __html: message?.content[0]?.text?.value }}
                      style={{ backgroundColor: message?.role === 'user' ? config.color : '#efecf1' }}
                    />
                  </div>
                ))
              ) : (
                <p>Data is not an array.</p>
              )}
              {isGenerating ?
                <TypingIndicator /> : null
              }
            </div>
            {isVoice ?
              <div className="or-wid-listeningTxt">Start talking...</div> : null
            }
            <div id="or-wid-inputContainer">
              <div className="or-wid-innerInput">
                <input
                  type="text"
                  id="or-wid-compose-input"
                  placeholder="Type your message..."
                  value={newMessageInput}
                  onChange={(e) => setNewMessageInput(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
                {!isVoice ?
                  <div className="or-wid-micContainer" onClick={handleVoiceStart} onMouseUp={handleVoiceEnd}>
                    <IoMicOutline className="or-wid-unfilledMic" style={{ fontSize: '22px' }} />
                  </div> : <div className="or-wid-micContainer" onClick={handleVoiceEnd}>
                    <FaMicrophone className="or-wid-filledMic" style={{ color: 'var(--brand-color)', fontSize: '18px' }} />
                  </div>
                }
              </div>
              <button className="or-wid-sendBtn" style={{ backgroundColor: config.color }} onClick={() => {
                sendUserMessageToOpenAI(newMessageInput);
                const newMessage = {
                  role: 'user',
                  content: [{ text: { value: newMessageInput } }],
                  created_at: Date.now(),
                };
                setAllMessages((prevMessages) => [...prevMessages, newMessage]);
                setNewMessageInput('');
              }}>
                <FaPaperPlane id="or-wid-paper-plane" />
              </button>
            </div>
          </div>
        )
      )}
    </div>
  );
}

export default ChatWidget;
