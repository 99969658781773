import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Home/Home.js';
import EmbedCode from './EmbedCode_Modal/EmbedCode.js';
import ConfigureWidget from './ConfigureWidget/ConfigureWidget.js';
import './App.css';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/customize-widget" element={<ConfigureWidget />} />
          <Route path="/embed-code" element={<EmbedCode />} />
          <Route path="/create-account" element={<Home />} />
          <Route path="/prototype" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
