import React, { useState, useEffect, useRef } from 'react';
import ChatCanvas from '../Chat/Chat';
import SearchResult from '../SeachResults/SearchResult.js';
import styles from './home.module.scss';
import { MdEditSquare } from "react-icons/md";
import { collection, addDoc, updateDoc, doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../firebase/firebaseApp';
import Loader from '../Loader/Loader'
import formatString from '../Docs/formatString';
import '../App.css';


function Home() {
    const [assistantDoc, setAssistantDoc] = useState(null);
    const [assistant, setAssistant] = useState(null);
    const [rawSearch, setRawSearch] = useState(null);
    const [allUrls, setAllUrls] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [thread, setThread] = useState("");
    const [showLoader, setShowLoader] = useState(false);
    const [researchDocId, setResearchDocId] = useState(null);
    const [productsGen, setProductsGen] = useState("");
    const [supportGen, setSupportGen] = useState("");
    const [additionalGen, setAdditionalGen] = useState("");
    const [trainingData, setTrainingData] = useState(null);
    const [companyFile, setCompanyFile] = useState(null);
    const [companyOverview, setCompanyOverview] = useState(null);
    const [searchResults, setSearchResults] = useState(null);
    const [genStep, setGenStep] = useState("Waiting");
    const [assistantName, setAssistantName] = useState("Hello World");
    const [genStatus, setGenStatus] = useState("Extracting website urls...")
    const [userInput, setUserInput] = useState("");
    const [research, setResearch] = useState(null);
    const [openaiResearchFile, setOpenaiResearchFile] = useState(null)
    const targetElementRef = useRef(null);
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const thread = urlParams.get('thread');
        const research_doc_id = urlParams.get('id');
        if (thread) {
            setThread(thread)
            getThread(thread)
        }
        if (research_doc_id) {
            setResearchDocId(research_doc_id)
            getResearchDoc(research_doc_id)
        }
    }, []);

    const getResearchDoc = async (id) => {
        try {
            const researchDocRef = doc(db, 'research', id);
            const researchDocSnapshot = await getDoc(researchDocRef);
            if (researchDocSnapshot.exists()) {
                const researchData = researchDocSnapshot.data();
                console.log('Research document data:', researchData);
                setAssistantDoc(researchData.assistant_doc_id)
                setAllUrls(researchData.search_data.site_links);
                setCompanyFile(researchData.search_data.openai_search_file)
                setAssistantName(researchData.search_data.homepage)
                setSearchResults(researchData.search_data.page_content)
                setCompanyOverview(researchData.company_overview)
                setOpenaiResearchFile(researchData.openai_research_file)
                getThread(researchData.thread_id)
                localStorage.setItem("thread_id", researchData.thread_id)
                localStorage.setItem('reseachDoc', researchData.docId);
            } else {
                console.log('Research document not found');
            }
        } catch (error) {
            console.error('Error fetching research document:', error.message);
        }
    };

    const getThread = async (thread) => {
        try {
            const response = await fetch('https://chat-wize.replit.app/api/threads', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    thread: thread
                }),
            });
            if (!response.ok) {
                throw new Error(`Failed to fetch data: ${response.statusText}`);
            }

            const responseData = await response.json();
            console.log('Thread Recieved', responseData)
            setResearch(responseData)
            setGenStep('Finished')
        } catch (error) {
            console.error('Error fetching data:', error.message);
        }
    };

    useEffect(() => {

        if (!editMode) {
            scrollToBottom();
        }

    }, [allUrls, searchResults, research, companyOverview]);

    const addGenerationURLToFirebase = async (url) => {
        try {
            const urlCollection = collection(db, 'generationURLs');
            const newDocRef = await addDoc(urlCollection, { url: url, created_at: new Date() });
            return newDocRef.id; // Return the new document ID if needed
        } catch (error) {
            throw error; // Throw the error for handling at the calling site
        }
    };

    //Create Research Doc Get ID
    const createResearchDocInFirebase = async (search_data) => {
        try {
            const urlCollection = collection(db, 'research');
            const newDocRef = await addDoc(urlCollection, { search_data, created_at: new Date() });
            await updateDoc(newDocRef, { docId: newDocRef.id });
            localStorage.setItem('reseachDoc', newDocRef.id);
            setResearchDocId(newDocRef.id);
            console.log("Research document created successfully with ID:", newDocRef.id);
            return newDocRef.id; // Make sure to return the ID
        } catch (error) {
            throw error;
        }
    };


    const startScrape = async (url) => {
        try {
            console.log('Website Scrape Request Started')
            const response = await fetch('https://chat-wize.replit.app/api/start-scrape', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    url: url
                }),
            });
            if (!response.ok) {
                throw new Error(`Failed to fetch data: ${response.statusText}`);
            }
            console.log('Website Scrape Recieved')
            const responseData = await response.json();
            console.log(responseData.pages)

            setTimeout(() => {
                setAllUrls(responseData.pages)
                localStorage.setItem('urls', responseData.pages);
                setGenStatus(responseData.status)
                getRawSearch(userInput, responseData.pages)
            }, 3000);


        } catch (error) {
            console.error('Error fetching data:', error.message);
        }
    };



    const updateResearchDocInFirebase = async (docId, updatedData) => {
        try {
            const urlCollection = collection(db, 'research');
            const docRef = doc(urlCollection, docId);
            await updateDoc(docRef, updatedData);
        } catch (error) {
            throw error; // Throw the error for handling at the calling site
        }
    };

    function scrollToBottom() {
        // const element = document.body;
        // element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
        if (targetElementRef.current) {
            targetElementRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
        }

    }

    const getRawSearch = async (homepage, website_urls) => {
        try {
            console.log('Raw Search Request Started')
            const response = await fetch('https://chat-wize.replit.app/api/start-research', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    domains: homepage
                }),
            });
            if (!response.ok) {
                throw new Error(`Failed to fetch data: ${response.statusText}`);
            }
            const responseData = await response.json();
            //console.log(responseData);

            let sr = JSON.parse(responseData.full_search)
            //console.log('sr', sr)
            setTimeout(() => {
                setCompanyOverview(sr.answer);
                setGenStatus(responseData.status)
                setGenStep(responseData.step)
                uploadCompanyFile(sr.results, homepage, website_urls)
            }, 10000);

            // Set searchResults after companyOverview with an additional delay of 1000 milliseconds
            setTimeout(() => {
                setSearchResults(sr.results);
                setGenStatus("Creating Company Overview...")
            }, 5000);
        } catch (error) {
            console.error('Error fetching data:', error.message);
        }
    };

    const uploadCompanyFile = async (research, homepage, website_urls) => {
        try {
            //console.log("urls", website_urls);
            let researchDoc = {}
            researchDoc.homepage = homepage
            researchDoc.website_content = research
            if (Array.isArray(website_urls)) {
                researchDoc.website_urls = website_urls
            } else {
                researchDoc.website_urls = [website_urls]
            }

            setTrainingData(researchDoc)
            let formatted = await formatString(researchDoc)

            const response = await fetch('https://chat-wize.replit.app/api/upload-files', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    research: researchDoc
                }),
            });
            if (!response.ok) {
                throw new Error(`Failed to fetch data: ${response.statusText}`);
            }
            console.log('Company File Upload')
            const responseData = await response.json();

            await createResearchDocInFirebase({
                homepage: homepage,
                site_links: website_urls,
                page_content: research,
                openai_raw_search_file: responseData.file,
            });
            setCompanyFile(responseData.file)
            console.log(responseData.status)
            setGenStatus(responseData.status)
            setGenStep(responseData.step)
            getProductServices(responseData.raw, responseData.domains, responseData.file)

        } catch (error) {
            console.error('Error fetching data:', error.message);
        }
    };


    const getProductServices = async (raw, domains, file) => {
        try {
            console.log("Product/Services sysnthesis started");
            //console.log(file)
            const response = await fetch('https://chat-wize.replit.app/api/product-services', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    raw: raw,
                    domains: domains,
                    file: file
                }),
            });
            if (!response.ok) {
                throw new Error(`Failed to fetch data: ${response.statusText}`);
            }
            console.log('Sysnthesis recieved for products/services')
            const responseData = await response.json();
            //console.log('Product/Services Docs', responseData);
            setProductsGen(responseData.messages.data[0].content[0].text.value)
            setResearch(responseData);
            setGenStep(responseData.step);
            setThread(responseData.thread_id)
            localStorage.setItem('thread_id', responseData.thread_id)
            setGenStatus(responseData.status)


            getTrainingDocs(responseData.raw, responseData.domains, responseData.thread_id, responseData.file)
        } catch (error) {
            console.error('Error fetching data:', error.message);
        }
    };

    const getTrainingDocs = async (raw, domains, thread_id, file) => {
        try {
            console.log("Training sysnthesis started");
            const response = await fetch('https://chat-wize.replit.app/api/training-docs', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    raw: raw,
                    domains: domains,
                    thread_id: thread_id,
                    file: file
                }),
            });
            if (!response.ok) {
                throw new Error(`Failed to fetch data: ${response.statusText}`);
            }
            const responseData = await response.json();
            //console.log('Training Docs', responseData);
            setResearch(responseData);
            setGenStatus(responseData.status)
            setGenStep(responseData.step);
            setSupportGen(responseData.messages.data[0].content[0].text.value)
            getAdditioanlDocs(responseData.raw, responseData.domains, responseData.thread_id, responseData.file)

        } catch (error) {
            console.error('Error fetching data:', error.message);
        }
    };

    const getAdditioanlDocs = async (raw, domains, thread_id, file) => {
        try {
            console.log("Additional detail sysnthesis started");
            const response = await fetch('https://chat-wize.replit.app/api/additional-docs', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    raw: raw,
                    domains: domains,
                    file: file,
                    thread_id: thread_id
                }),
            });
            if (!response.ok) {
                throw new Error(`Failed to fetch data: ${response.statusText}`);
            }
            console.log('Additional details sysnthesis recieved')
            const responseData = await response.json();
            //console.log('Additional Docs', responseData);
            setResearch(responseData);
            setGenStep(responseData.step);

        } catch (error) {
            console.error('Error fetching data:', error.message);
        }
    };

    const createAssistant = async () => {
        setShowLoader(true)
        let fileContent = { ...research }
        fileContent.training_data = research
        fileContent.company_overview = companyOverview
        let file_research = await createAssistantFile(fileContent, "research")
        // console.log("file_research", file_research)
        let siteContent = {}
        siteContent.page_content = searchResults
        siteContent.website_links = allUrls
        siteContent.homepage = assistantName
        let site_research = await createAssistantFile(siteContent, "website")
        // console.log("site_research", site_research)

        try {
            const response = await fetch('https://chat-wize.replit.app/api/create-assistant', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    instructions: "You are a customer support bot. Analyze the website-research.jsonl file to learn about the company, website pages, and links. The file company-research.jsonl gives you details about products and/or services, the company overview, as well as details on how to respond as a customer support representative for the company. Use this information to answer customer questions from the perspective of someone who works for the company. Do not respond that data in not contained in your file, if you don't know the answer direct them to contact the company. You are speaking with customers so be human and professional and do not talk about missing data in the uploaded files",
                    name: assistantName,
                    files: [file_research.file_content.id, site_research.file_content.id]
                }),
            });

            if (!response.ok) {
                throw new Error(`Failed to fetch data: ${response.statusText}`);
            }
            const responseData = await response.json();
        
            const assistant = responseData.assistant

            setAssistant(assistant)
            //Send Assistant to FB
            try {
                if (assistantDoc) {
                   
                    //Get the assistant doc
                    const assistantDocRef = doc(db, 'assistants', assistantDoc);
                    const assistantDocSnapshot = await getDoc(assistantDocRef);
                    const docId = assistantDocRef.id;
                    let old_assistant = assistantDocSnapshot.data().assistant
                    //Update the asst_id
                    let thread = localStorage.getItem('thread_id')
                    console.log(thread)
                    let res = localStorage.getItem('reseachDoc')
                    console.log(assistant)
                    await updateDoc(assistantDocRef, { assistant });
                    await updateResearchDocInFirebase(localStorage.getItem('reseachDoc'), { training_data: research, thread_id: thread, company_overview: companyOverview, assistant_doc_id: docId, openai_research_file: file_research.file_content, openai_site_content: site_research.file_content })
                    await deleteAssitant(old_assistant)
                    await deleteFiles(old_assistant)
                    window.location.href = '/customize-widget?id=' + localStorage.getItem('reseachDoc')
                } else {
                    alert("else")
                    const urlCollection = collection(db, 'assistants');
                    const newDocRef = await addDoc(urlCollection, { assistant });
                    const docId = newDocRef.id;
                    let thread = localStorage.getItem('thread_id')
                    let res = localStorage.getItem('reseachDoc')
                    await updateDoc(newDocRef, { docId });
                    await updateDoc(newDocRef, { reasearch_doc_id: res });
                    await updateResearchDocInFirebase(localStorage.getItem('reseachDoc'), { training_data: research, thread_id: thread, company_overview: companyOverview, assistant_doc_id: docId, openai_research_file: file_research.file_content, openai_site_content: site_research.file_content })
                    window.location.href = '/customize-widget?id=' + localStorage.getItem('reseachDoc')
                }

            } catch (error) {
                throw error; // Throw the error for handling at the calling site
            }

        } catch (error) {
            console.error('Error fetching data:', error.message);
        }
    };



    const handleUserInputChange = (event) => {
        setUserInput(event.target.value);
    };


    const createAssistantFile = async (data, type) => {
        try {


            const response = await fetch('https://chat-wize.replit.app/api/upload-research', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data,
                    type: type
                }),
            });
            if (!response.ok) {
                throw new Error(`Failed to fetch data: ${response.statusText}`);
            }

            const responseData = await response.json();
            //console.log(responseData)
            return responseData
        } catch (error) {
            console.error('Error fetching data:', error.message);
        }
    }



    const deleteAssitant = async (old_assistant) => {
        try {
            const response = await fetch('https://chat-wize.replit.app/api/delete-assistant', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    assistant: old_assistant,
                }),
            });
            if (!response.ok) {
                throw new Error(`Failed to fetch data: ${response.statusText}`);
            }

            const responseData = await response.json();
            console.log(responseData)
            return responseData
        } catch (error) {
            console.error('Error fetching data:', error.message);
        }

    }

    const deleteFiles = async (old_assistant) => {
        try {
            const response = await fetch('https://chat-wize.replit.app/api/delete-files', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    assistant: old_assistant,
                }),
            });
            if (!response.ok) {
                throw new Error(`Failed to fetch data: ${response.statusText}`);
            }
            const responseData = await response.json();
            console.log(responseData)
            return responseData
        } catch (error) {
            console.error('Error fetching data:', error.message);
        }
    }



    const checkUrlOnline = async (url) => {
        if (genStep !== "Waiting") {
            setGenStep("Waiting")
            setAllUrls([])
            setGenStatus('Extracting website urls...')
            setSearchResults(null)
            setResearch(null)
            setCompanyOverview(null)
        }

        try {
            //const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
            const urlValid = formatURL(url);
            setUserInput(urlValid);
            if (urlValid && url !== "") {
                addGenerationURLToFirebase(urlValid)
                setAssistantName(urlValid)
                startScrape(urlValid);
                setGenStep("Waiting")
                setGenStep("Extracting website urls...")
            }
        } catch (error) {
            alert("The URL is not reachable or an error occurred");
        }
    };

    function formatURL(url) {
        // Check if the URL starts with "http://" or "https://"
        if (!url.startsWith("http://") && !url.startsWith("https://")) {
            // If not, add "http://www."
            url = "http://www." + url;
        }
        return url;
    }

    const handleCompanyEdit = (event) => {
        console.log(event.target.innerHTML)
        localStorage.setItem('company_overview', event.target.innerHTML)
        setCompanyOverview(event.target.innerHTML)
    };

    const companyOverviewRef = useRef(null);
    useEffect(() => {
        if (editMode) {
            companyOverviewRef.current.focus();
        }
    }, [editMode]);

    const updateMsg = (msg, msgId) => {
        let newRes = { ...research }
        for (let i = 0; i < newRes.messages.data.length; i++) {
            //Find the message and replace
            if (newRes.messages.data[i].id == msgId) {
                newRes.messages.data[i].content[0].text.value = msg
            }
        }
        for (let i = 0; i < newRes.messages.body.data.length; i++) {
            //Find the message and replace
            if (newRes.messages.body.data[i].id == msgId) {
                newRes.messages.body.data[i].content[0].text.value = msg
            }
        }
        setResearch(newRes)
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            checkUrlOnline(userInput)
        }
    };


    return (
        <div>
            {showLoader ? 
                <Loader/>:null
            }
            
            <div className={styles.headerContainer}>
            <div className={styles.signUpBtn} onClick={() => { window.location.href = "https://outrise.ai/why.html" }}>How it works</div> 
                <div className={styles.signUpBtn} onClick={() => { window.location.href = "https://app.outrise.ai/pricing" }}>Pricing</div> 
                <div className={styles.signUpBtn} onClick={() => { window.location.href = "https://app.outrise.ai/signup" }}>Sign Up</div>
                <div className={styles.loginBtn} onClick={() => { window.location.href = "https://app.outrise.ai" }}>Login</div>
            </div>
            <div className={styles.app}>
                <div className={styles.contentWrapper}>
                    <div className="training-main-wrapper">
                        <img style={{ display: 'block', height: '100px', margin: 'auto' }} src="/OutRise_Logo.png"></img>
                        <h3 className="logoH1" style={{ color: 'white', fontSize: '58px', textAlign: "center", marginBottom: '0px', marginTop: '-10px' }}>OutRise.ai</h3>

                        <h1 style={{ color: 'white', fontSize: '20px', textAlign: "center", width: '75%', marginLeft: 'auto', marginRight: 'auto', marginTop: '10px', marginBottom: '40px', opacity: .7, fontWeight: '500' }}>Easily train custom GPT's and embed them on your business website</h1>
                        <div className="training-input-Container " >
                            <input type="text" className="traning-input" onChange={handleUserInputChange} onKeyDown={handleKeyDown} value={userInput} placeholder="Enter your business website url" />
                        </div>
                        <button className="traning-btn-start" onClick={() => { checkUrlOnline(userInput) }}>{"Train My Assistant"}</button>
                        <div style={{ display: 'grid', placeContent: 'center', gridTemplateColumns: '34px 1fr', width: '220px', margin: 'auto', marginTop: '20px' }}>
                            <img style={{ height: '24px' }} src="/ChatGPT_logo.svg.png"></img>
                            <h4 style={{ color: 'white', fontSize: '18px', textAlign: "left", width: '180px', marginLeft: 'auto', marginRight: 'auto', marginTop: '0px', marginBottom: '0px', opacity: .7, fontWeight: '500' }}>Powered by OpenAI</h4>
                        </div>
                    </div>
                    <div id="docContent" style={{ marginTop: '24px' }}>
                        <div>
                            {Array.isArray(allUrls) && allUrls.length > 0 ? (
                                <ul>
                                    <h3>Analyzing Site Links</h3>
                                    {allUrls.map((result, index) => (
                                        <div key={index} className={styles.linkWrapper}>
                                            <a className={styles.linkText} href={result}>{result}</a>
                                        </div>
                                    ))}
                                </ul>
                            ) : null}
                        </div>
                        <div className={styles.sr_wrapper}>
                            {searchResults
                                ? searchResults.map((result, index) => (
                                    <div key={index}>
                                        <SearchResult result={result} />
                                    </div>
                                ))
                                : null}
                        </div>
                        {companyOverview ?
                            <div>
                                <div className={styles.overviewContaienr}>
                                    <h1>Company Overview</h1>
                                    {!editMode ?
                                        <div className={styles.editBtn} onClick={() => { setEditMode(true) }}>
                                            <MdEditSquare style={{ fontSize: '20px' }} />
                                        </div> : <button className={styles.editBtn} style={{ fontWeight: 700, backgroundColor: 'green' }} onClick={() => { setEditMode(false) }}>Done Editing </button>
                                    }

                                    <p ref={companyOverviewRef} contentEditable={editMode}
                                        suppressContentEditableWarning={true} onBlur={handleCompanyEdit}>{companyOverview}</p>
                                </div>
                            </div> : null
                        }

                    </div>
                    <div style={{ color: 'blue' }}>
                        {research ? (
                            <ChatCanvas messageAry={research.messages.data} updateMsg={updateMsg}></ChatCanvas>
                        ) : null}
                    </div>
                    {genStep !== "Finished" && genStep !== "Waiting" ?
                        <div className={styles.genContainer} >
                            <div className={styles.genStepWrapper}>
                            </div>
                            <div className={styles.genStepInner}>
                                <div className={styles.genTextContainer}>
                                    <div className={styles.genh1}>
                                        {genStatus}
                                    </div>
                                    <div className={styles.gensub} >
                                        {"Sit back and relax, this process will take several minutes..."}
                                    </div>
                                </div>
                            </div>
                        </div> : null
                    }
                    <div ref={targetElementRef}></div>
                    {genStep === "Finished" ?
                        <div>
                            {!assistantDoc ?
                                <button
                                    className={styles.testMyAssistant}
                                    onClick={createAssistant}>
                                    Chat With Assistant
                                </button> : <button
                                    className={styles.testMyAssistant}
                                    onClick={createAssistant}>
                                    Return to Assistant
                                </button>
                            }
                            <button className={styles.uploadDocs}>Upload Additional Traning Docs
                            </button>
                            <p style={{ color: 'white', opacity: .8, textAlign: 'center' }}>Supported File Types: .pdf .doc .txt .json .zip</p>
                        </div> : null
                    }
                </div>


            </div>
            {genStep === "Waiting" ?
                <div className={styles.footerContainer}>
                    <a className={styles.links} href="/privacy.html">Privacy Policy</a>
                    <a className={styles.links} href="/cookies.html">Cookies</a>
                    <a className={styles.links} href="/terms.html">Terms & Conditions</a>
                </div> : null

            }

        </div>


    );
}
export default Home;
