import React, { useState } from 'react';
import MessageList from './MessageList';
import Message from './MessageBubble'; // Assuming you have a Message component

const ChatCanvas = ({ messageAry, updateMsg }) => {
  
  return (
    <div className="chat-app" >
      <MessageList messages={messageAry} updateMsg={updateMsg}/>
    </div>
  );
};

export default ChatCanvas;
