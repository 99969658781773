import React, { useRef, useEffect } from 'react';
import lottie from 'lottie-web';
import animationData from './outrise.json';

const Loader = () => {
  const lottieContainer = useRef(null);

  useEffect(() => {
    lottie.loadAnimation({
      container: lottieContainer.current,
      renderer: 'svg',
      loop: true,
      height: 300,
      width: 300,
      autoplay: true,
      animationData: animationData,
    });
  }, []);

  return (
    <div style={{height: '100vh', width: '100vw', display:'grid', placeContent:'center', backgroundColor:'rgba(0,0,0,.3)', backdropFilter:'blur(30px)', position: 'fixed', zIndex:1000}}>
        <div style={{width: '200px', height:'200px'}} ref={lottieContainer}></div>
        <div style={{color:'white', textAlign:'center', fontFamily:'DM Sans', marginTop: '16px', fontWeight: 700}}>Setting up your assistant...</div>
    </div>
  ) 
  
};

export default Loader;
