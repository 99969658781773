// ModalComponent.jsx
import React, { useState, useEffect } from 'react';
import { FaCopy, FaTimes } from 'react-icons/fa';
import { CopyBlock, dracula } from 'react-code-blocks';
import styles from './embed.module.scss';
import CopyToClipboard from 'react-copy-to-clipboard';

const EmbedCode = ({ onClose, embedCode }) => {
  const [isCopied, setIsCopied] = useState(false);
  const [assistant_id, setAssistant_id] = useState(false);
  const CopyToClipboard = (e) => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
    console.log(e)
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('assistant_id');
    if (id) {
      setAssistant_id(id)
    }
  }, []);

  return (
    <div className={styles.embedContentContainer}>

      <h1>Embed your chat widget on your website</h1>
      <p>Copy and paste this code snippet within the body tag of your index.html file</p>
      {assistant_id ?
        <CopyBlock
          text={`<div id="outrise_chat_widget" data-symbol="${assistant_id}" ></div>
<link href="https://chat.outrise.ai/index.css" rel="stylesheet"/>
<script src="https://chat.outrise.ai/index.js"></script>`}
          language={'html'}
          showLineNumbers={true}
          style={{ padding: '16px' }}
          wrapLines={true}
          onCopy={CopyToClipboard}
        /> : null
      }

      {isCopied ?
        <div style={{ height: '50px', marginTop: '30px', textAlign: 'center', width: '100%', backgroundColor: 'var(--brand-color)', display: 'grid', placeContent: 'center' }}>
          <div style={{ color: 'white', fontFamily: 'DM Sans', fontWeight: 700 }}>Code Snippet Copied to Clipboard!</div>
        </div> : null
      }
    </div>
  );
};

// (function (w, d, s, o, f, js, fjs) {
//   w['OutRise-Chat-Widget'] = o;
//   w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) };
//   var widgetContainer = d.createElement('div');
//   widgetContainer.id = 'outrise_chat_widget';
//   widgetContainer.setAttribute('data-symbol', ${assistant_id});
//   d.body.appendChild(widgetContainer);
//   js = d.createElement(s);
//   fjs = d.getElementsByTagName(s)[0];
//   js.id = o;
//   js.src = f;
//   js.async = 1;
//   fjs.parentNode.insertBefore(js, fjs);
// }(window, document, 'script', 'mw', 'https://outrisechatwidgetscript.replit.app/index.js'));


export default EmbedCode;
