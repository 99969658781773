// MessageBubble.jsx
import React, { useState, useEffect, useRef } from 'react';
import styles from './CSS Modules/messagebubble.module.scss';
import { MdEditSquare } from 'react-icons/md';

const MessageBubble = ({ messageItem, updateMsg }) => {
  const { content, role } = messageItem;
  const [editMode, setEditMode] = useState(false);
  const [editedContent, setEditedContent] = useState([]);

  useEffect(() => {
   
    // Initialize editedContent with the initial content
    setEditedContent(content);
  }, [content]);

  const msgRef = useRef(null);

  useEffect(() => {
    if (editMode) {
      msgRef.current.focus();
    }
  }, [editMode]);

  const handleInputChange = (event) => {
    // console.log(event.target.innerHTML)
    updateMsg(event.target.innerHTML, messageItem.id)
  };

  return (
    <div className={styles.container}>
      {!editMode ?
        <div className={styles.editBtn} onClick={() => { setEditMode(true) }}>
          <MdEditSquare style={{ fontSize: '20px' }} />
        </div> : <button className={styles.editBtn} style={{ fontWeight: 700, backgroundColor: 'green' }} onClick={() => { setEditMode(false) }}>Done Editing </button>
      }

      {editedContent.map((item, index) => (
        <div key={index}>
          {item.type === 'text' && (
            <span
              ref={msgRef}
              contentEditable={editMode}
              suppressContentEditableWarning={true}
              dangerouslySetInnerHTML={{ __html: item.text.value }}
              className={styles.text}
              data-index={index}
              onBlur={handleInputChange}
            />
          )}
        </div>
      ))}
      {/* <p className={styles.role}>{`Role: ${role}`}</p>
      <button>Edit</button> */}
    </div>
  );
};

export default MessageBubble;
