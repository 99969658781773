// SearchResult.jsx
import React from 'react';
import styles from './searchresult.module.scss';
const SearchResult = ({ result, key }) => {
    return (
        <div className={styles.container}>
            <div className={styles.webpagePill}>
                AI Content Extraction
            </div>
            <h3 className={styles.title}>{result.title}</h3>
            <h5 className={styles.score}>Confidence Weight: {(result.score * 100).toFixed(2)+'%'}</h5>
            <div className={styles.contentWrapper}>
                <p>{result.content} </p>
            </div>

            <a href={result.url} className={styles.link} target="_blank" rel="noopener noreferrer">
                View Page
            </a>
        </div>
    );
};

export default SearchResult;
