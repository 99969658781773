export default async function aformatResearchDoc(researchDoc) {
    // Initialize the formatted string with company overview and homepage
    let formattedString = `Company Overview: ${researchDoc.company_overview}\n Company Homepage: ${researchDoc.homepage}\n\n Webpages:`;

    // Add website content details
    formattedString += researchDoc.website_content.map((content) => {
      return `\nPage: ${content.title}\nScore: ${content.score}\nContent: ${content.content}\nURL: ${content.url}\n`;
    }).join('\n');
  
    // Add website URLs
    formattedString += '\n\nWebsite URLs:\n';
    formattedString += researchDoc.website_urls.map((url) => `${url}\n`).join('');
  
    return formattedString;
  }