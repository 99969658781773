// MessageList.jsx
import React from 'react';
import Message from './MessageBubble';

const MessageList = ({ messages, updateMsg }) => {
  // Filter out messages with role 'assistant' and sort by created_at timestamp
  const filteredAndSortedMessages = messages
    .filter((messageItem) => messageItem.role === 'assistant')
    .sort((a, b) => new Date(a.created_at) - new Date(b.created_at));

  return (
    <div>
      {filteredAndSortedMessages.map((messageItem) => (
        <Message key={messageItem.id} messageItem={messageItem} updateMsg={updateMsg}/>
      ))}
    </div>
  );
};

export default MessageList;
